import { gql } from "@apollo/client";

export const GETPAGESANDSTORIES = gql`
	query GetPages {
		pages(sort: "OrderNumber:asc") {
			OrderNumber
			Name
			Stories(sort: "PositionNumber:asc") {
				PositionNumber
				Title
				Tag
				Image {
					url
				}
				StoryURL
				PromotionalDate
			}
			Template {
				Name
			}
		}
	}
`;
