// import {
// 	ApolloClient,
// 	ApolloLink,
// 	HttpLink,
// 	InMemoryCache,
// } from "@apollo/client";
// import { onError } from "@apollo/client/link/error";

// const url = "https://strapi-60voices-dev.experience.stjude.org/graphql";

// const httpLink = new HttpLink({
// 	uri: url,
// });

// const errorLink = onError(({ graphQLErrors, networkError }) => {
// 	if (graphQLErrors)
// 		graphQLErrors.forEach(({ message, locations, path }) =>
// 			console.log(
// 				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
// 			)
// 		);

// 	if (networkError) console.log(`[Network error]: ${networkError}`);
// });

// const link = ApolloLink.from([errorLink, httpLink]);

// export default new ApolloClient({
// 	link,
// 	cache: new InMemoryCache({
// 		typePolicies: {
// 			Query: {
// 				fields: {
// 					getInvoiceTotalByUser: {
// 						merge(existing, incoming) {
// 							return incoming;
// 						},
// 					},
// 					listItemsByInvoice: {
// 						merge(existing, incoming) {
// 							return incoming;
// 						},
// 					},
// 				},
// 			},
// 		},
// 	}),
// });

import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

const link = new HttpLink({
	uri: process.env.REACT_APP_STRAPI_URL || "https://strapi-60voices-dev.experience.stjude.org/graphql",
});

const client = new ApolloClient({
	link,
	cache: new InMemoryCache(),
});

export default client;
