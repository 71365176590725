export const AnalyticsEvent = (
	language = "english",
	path = "/",
	pageName,
	section,
	subsection
) => {
	if (typeof stjude !== "undefined") {
		// eslint-disable-next-line
		stjude.digitalData.page = {
			language,
			path,
			pageName: `60_Voices:${pageName}`,
			section,
			subsection,
		};

		// eslint-disable-next-line
		stjude.digitalData.event = [
			{
				category: {},
				eventInfo: {
					eventName: "spa-change",
					eventAction: "",
					timeStamp: new Date(),
				},
			},
		];
	} else {
		console.log("No analytics object defined.");
	}
};
