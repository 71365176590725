import React, { useEffect, useRef, useState, memo } from "react";
import "./LargeDiamondGrid.scss";
import gsap from "gsap";
import is from "is_js";
// import { AnalyticsEvent } from "./../../lib/analytics-event";
import Div100vh from "react-div-100vh";

function DiamondGrid({
	pageSelected,
	previousPage,
	navDirection,
	pageData,
	tempImages,
	homeEventHandler,
	updatePauseCarouselHandler,
}) {
	// useEffect(() => {
	// 	AnalyticsEvent("english", "/", pageSelected);
	// }, [pageSelected]);

	let gridRef = useRef(null);
	let spRef = useRef(null);
	let purposeHeadingRef = useRef(null);
	let logoRef = useRef(null);
	let wordmarkTextRef = useRef(false);

	useEffect(() => {
		const { innerWidth, innerHeight } = window;

		gsap.to(gridRef, { duration: 2, css: { visibility: "visible" } });

		// Show wordmarkTextRef
		gsap.to(wordmarkTextRef, {
			duration: 0.6,
			css: { opacity: 1 },
			delay: 5,
		});

		// Show gridRef when first animation begins
		gsap.to(gridRef, {
			duration: 1,
			// css: { visibility: "visible" },
			delay: 5.1,
		});

		// Bring grid article pieces into viewport
		gsap.from(".diamondPieceNegative", {
			duration: 2.5,
			x: 1500,
			y: 100,
			scale: 0,
			opacity: 0,
			delay: 5,
			stagger: 0.05,
		});

		gsap.set("div.diamond-view-0 > .diamondPieceArticle", {
			display: "block",
			delay: 5,
		});

		// Bring grid article pieces into viewport
		gsap.fromTo(
			"div.diamond-view-0 > .diamondPieceArticle",
			{
				x: 1500,
				y: 100,
				scale: 0,
				opacity: 0,
				zIndex: 1500,
			},
			{
				x: 0,
				y: 0,
				scale: 1,
				opacity: 1,
				zIndex: 1500,
				delay: 5,
				stagger: 0.05,
				duration: 2.5,
			}
		);

		// Show Logo element as the diamond grid moves into position
		gsap.to(logoRef, {
			duration: 1,
			css: { opacity: 1 },
			delay: 5,
		});

		// Show purpose heading wordmark
		gsap.to(purposeHeadingRef, {
			duration: 0.5,
			opacity: 1,
			delay: 5,
		});

		// Show the SP Diamond once the diamond grid has moved into position
		gsap.from(spRef, {
			duration: 1.5,
			x: is.mobile() && innerWidth < innerHeight ? 0 : -1700,
			y: is.mobile() && innerWidth < innerHeight ? 1700 : 0,
			opacity: 0,
			delay: 5.2,
		});

		// hide tempDiamondContent out of viewport
		gsap.to(".tempDiamondContent", {
			duration: 3,
			x: 100,
			y: -100,
			delay: 10,
		});

		gsap.to(".tempDiamondContent", {
			duration: 0.4,
			css: { opacity: 0 },
			delay: 10.1,
		});

		gsap.to(".diamondPieceNegative", {
			duration: 0,
			css: { opacity: 0.25 },
			delay: 10.4,
		});
	}, []);

	useEffect(() => {
		if (!previousPage) return;

		// fade out current images
		gsap.to(`div.diamond-view-${previousPage - 1} > .diamondPieceArticle`, {
			opacity: 0,
			zIndex: -1,
			duration: 0.6,
			stagger: 0.05,
		});

		gsap.set(`div.diamond-view-${previousPage - 1} > .diamondPieceArticle`, {
			display: "none",
			delay: 0.6,
		});

		gsap.set(`div.diamond-view-${pageSelected - 1} > .diamondPieceArticle`, {
			display: "block",
		});

		// bring in new images from the correct direction
		gsap.fromTo(
			`div.diamond-view-${pageSelected - 1} > .diamondPieceArticle`,
			{
				x: navDirection === "right" ? 1700 : -1700,
			},
			{
				opacity: 1,
				x: 0,
				zIndex: 1500,
				stagger: 0.05,
				duration: 1,
				delay: 0.4,
			}
		);
	}, [pageSelected]);

	const onMouseEnter = (imgId) => {
		gsap.utils
			.toArray(`div.diamond-view-${pageSelected - 1} > .diamondPieceArticle`)
			.forEach((el) => {
				if (parseInt(el.id) === imgId) {
					gsap.to(el, { opacity: 1 });
				} else {
					gsap.to(el, { opacity: 0.6 });
				}
			});
	};

	const onMouseLeave = (imgId) => {
		gsap.to(`div.diamond-view-${pageSelected - 1} > .diamondPieceArticle`, {
			opacity: 1,
		});
	};

	return (
		<Div100vh
			className={`grid-wrapper layout-${
				pageData.pages[pageSelected - 1].Template.Name
			}`}
			ref={(el) => {
				gridRef = el;
			}}
		>
			{/* Brand */}
			<button
				className='brand-logo'
				ref={(el) => {
					logoRef = el;
				}}
				onClick={() => homeEventHandler()}
				aria-label='Go home'
			/>

			<div
				id='purposeHeading'
				ref={(el) => {
					purposeHeadingRef = el;
				}}
			></div>

			{/* Wordmark Description */}
			<div
				id='wordmarkText'
				ref={(el) => {
					wordmarkTextRef = el;
				}}
			>
				<p>
					For 60 years, supporters of{" "}
					<span style={{ display: "inline-block", wordWrap: "no-wrap" }}>
						St. Jude
					</span>{" "}
					Children&#8217;s Research Hospital have been united around one
					purpose: that no child should die in the dawn of life. Explore their
					stories by selecting a photo.
				</p>
			</div>

			{/* SP */}
			<div
				className={`grid-item diamond-sp`}
				ref={(el) => {
					spRef = el;
				}}
			></div>

			{pageData.pages.map((page, pIndex) => {
				return (
					<div
						className={`diamond-wrapper diamond-view-${pIndex} layout-${page.Template.Name}`}
						key={pIndex}
					>
						{page.Stories.map((story, sIndex) => {
							const imgId = (pIndex + 1) * 10 + sIndex;
							return (
								<a
									key={sIndex}
									id={imgId}
									href={"http://" + story.StoryURL}
									rel='noopener noreferrer'
									className={`grid-item diamond-large diamond-a${
										story.PositionNumber
									} grid-article diamondPieceArticle pageOneArticle ${
										!is.safari() ? "notSafariHover" : ""
									}`}
									target='_blank'
									onMouseEnter={() => {
										if (!is.safari() || (is.safari() && is.mobile())) {
											onMouseEnter(imgId);
										}

										updatePauseCarouselHandler(true);
									}}
									onMouseLeave={() => {
										if (!is.safari() || (is.safari() && is.mobile())) {
											onMouseLeave();
										}
										updatePauseCarouselHandler(false);
									}}
									onFocus={() => {
										if (!is.safari() || (is.safari() && is.mobile())) {
											onMouseEnter(imgId);
										}
									}}
									onBlur={() => {
										if (!is.safari() || (is.safari() && is.mobile())) {
											onMouseLeave();
										}
									}}
								>
									<img
										src={story.Image?.url}
										alt={`Read story "${story.Title}"`}
										className='temp_diamond_content'
										data-track={story.Tag?.replace(" ", "-")}
									/>
								</a>
							);
						})}
					</div>
				);
			})}
			{tempImages.map((imgSrc, index) => {
				return (
					index < 36 && (
						<div
							key={index}
							className={`grid-item diamond-large diamond-n${
								index + 1
							} grid-negative diamondPieceNegative`}
						>
							{!previousPage && ( // only render initially
								<div
									className={`temp_diamond_content tempDiamondContent`}
									style={{
										background: `#444 url(${imgSrc})`,
										backgroundRepeat: "no-repeat",
										backgroundSize: "cover",
									}}
								></div>
							)}
						</div>
					)
				);
			})}
		</Div100vh>
	);
}

export default DiamondGrid;
