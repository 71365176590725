import { useRef, useEffect, useState, useCallback } from "react";
import "./App.scss";
import is from "is_js";
import Div100vh from "react-div-100vh";
import { AnalyticsEvent } from "./lib/analytics-event";

// Component Imports
import LargeDiamondGrid from "./components/LargeDiamondGrid";

// GQL Imports
import { useQuery } from "@apollo/client";
import * as QUERY from "./lib/queries";

// GSAP Imports
import gsap from "gsap";

// Material UI Imports
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

function App() {
	// STATE/HOOKS
	const [pageSelected, setPageSelected] = useState(1);
	const [previousPage, setPreviousPage] = useState(null);
	const [navDirection, setNavDirection] = useState(null);
	const [touchStart, setTouchStart] = useState(0);
	const [touchEnd, setTouchEnd] = useState(0);
	const [previousTouch, setPreviousTouch] = useState(0);
	const [tempImages, setTempImages] = useState(null);
	// eslint-disable-next-line
	const [beginCarousel, setBeginCarousel] = useState(false);
	const [pauseCarousel, setPauseCarousel] = useState(false);

	let appRef = useRef(null);
	let sixtyTwoHeadingRef = useRef(null);
	let twentyTwoHeadingRef = useRef(null);
	let spanCharOneRef = useRef(null);
	let spanCharTwoRef = useRef(null);
	let mainRef = useRef(null);
	let leftNavButtonRef = useRef(null);
	let rightNavButtonRef = useRef(null);
	let pageMarkerContainerRef = useRef(null);

	// eslint-disable-next-line
	const { loading, error, data } = useQuery(QUERY.GETPAGESANDSTORIES);

	useEffect(() => {
		if (data) {
			// force browser to preload images
			data.pages.forEach((p) => {
				p.Stories.forEach((s) => {
					const img = new Image();
					img.src = s.Image?.url;
				});
			});
			// this gets all story images (excluding first page stories)
			// to be used for the 'temp' background images on the opening animation
			const temps = data.pages.reduce((images, page, index) => {
				if (index !== 0) {
					page.Stories.forEach((s) => {
						images.push(s.Image?.url);
					});
				}
				return images;
			}, []);

			setTempImages(temps);
			// Used to avoid flashing when hard reloading
			gsap.to(appRef, { duration: 1, css: { visibility: "visible" } });

			// Ensuring .hiddenChar is not visible
			gsap.to(".hiddenChar", { duration: 0.1, opacity: 0 });

			// Moving 1962 text from left to right (center)
			gsap.from(sixtyTwoHeadingRef, { duration: 2, x: -1000 });

			// Moving 2022 text from right to left (center)
			gsap.from(twentyTwoHeadingRef, { duration: 2, x: 1000 });

			// Changing color of '6' character to white once text is animated in
			gsap.to(spanCharOneRef, {
				duration: 1,
				css: { color: "#D11846" },
				delay: 2.2,
			});

			// Changing color of '0' character to white once text is animated in
			gsap.to(spanCharTwoRef, {
				duration: 1,
				css: { color: "#D11846" },
				delay: 2.2,
			});

			// Scale down 1962 text
			gsap.to(sixtyTwoHeadingRef, {
				duration: 1,
				scale: 0.75,
				// x: 37.5,
				y: is.mobile() ? 20 : 30,
				delay: 3.2,
			});

			// Scale down 2022 text
			gsap.to(twentyTwoHeadingRef, {
				duration: 1,
				scale: 0.75,
				// x: -37.5,
				y: is.mobile() ? -20 : -30,
				delay: 3.2,
			});

			// Fade out 19-2 & 2-22 text
			gsap.to(".spanToFade", {
				duration: 0.5,
				opacity: 0,
				delay: 4.3,
			});

			// Scale up and fade out 1962 text
			gsap.to(sixtyTwoHeadingRef, {
				duration: 1,
				opacity: 0,
				delay: 5,
			});

			// Scale up and fade out 2022 text
			gsap.to(twentyTwoHeadingRef, {
				duration: 1,
				opacity: 0,
				delay: 5,
			});

			// Stop displaying main container once grid loads
			gsap.to(mainRef, {
				duration: 0.2,
				css: { display: "none" },
				delay: 7.8,
			});

			// Display Navigation/Pagination Elements
			gsap.from(leftNavButtonRef, {
				duration: 0.5,
				opacity: 0,
				delay: 11,
			});

			gsap.from(rightNavButtonRef, {
				duration: 0.5,
				opacity: 0,
				delay: 11,
			});

			gsap.to(pageMarkerContainerRef, {
				duration: 0.5,
				opacity: 1,
				delay: 11,
			});

			const timeout = setTimeout(() => {
				setBeginCarousel(true);
			}, 10000);
		}
	}, [data]);

	// METHODS/HANDLERS
	const handleTouchStart = (e) => {
		setPreviousTouch(touchEnd);
		setTouchStart(e.targetTouches[0].clientX);
	};

	const handleTouchMove = (e) => {
		setTouchEnd(e.targetTouches[0].clientX);
	};

	const handleTouchEnd = () => {
		if (Math.floor(touchEnd) == Math.floor(previousTouch)) {
			return;
		}
		if (touchStart - touchEnd > 125) {
			handlePageChange("right");
		}
		if (touchStart - touchEnd < -125) {
			handlePageChange("left");
		}
	};

	// CAROUSEL - START
	useEffect(() => {
		if (beginCarousel) {
			const interval = setInterval(() => {
				if (!pauseCarousel) {
					AnalyticsEvent("english", "/", `${pageSelected + 1}:auto-advance`);
					handlePageChange("right");
				} else {
					clearInterval(interval);
				}
			}, 8000);
			return () => clearInterval(interval);
		}
	}, [beginCarousel, pageSelected, pauseCarousel]);

	const updatePauseCarouselHandler = (boolean) => {
		setPauseCarousel(boolean);
	};
	// CAROUSEL - END

	const handlePageChange = (value) => {
		setPreviousPage(pageSelected);
		setNavDirection(value);
		if (value === "left") {
			if (pageSelected === 1) {
				setPageSelected(10);
			} else {
				setPageSelected(pageSelected - 1);
			}
		}

		if (value === "right") {
			if (pageSelected === 10) {
				setPageSelected(1);
			} else {
				setPageSelected(pageSelected + 1);
			}
		}
	};

	const homeEventHandler = () => {
		setNavDirection("left");
		setPreviousPage(pageSelected);
		setPageSelected(1);
	};

	return !loading ? (
		<Div100vh
			ref={(el) => {
				appRef = el;
			}}
			className='container'
			style={{ height: `${is.mobile() ? 90 : 100}vh !important` }}
		>
			<div
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}
			>
				<main
					className='main'
					ref={(el) => {
						mainRef = el;
					}}
				>
					<div
						id='sixtyTwoHeading'
						ref={(el) => {
							sixtyTwoHeadingRef = el;
						}}
					>
						<span className='spanToFade'>19</span>
						<span
							className='spanChar'
							ref={(el) => {
								spanCharOneRef = el;
							}}
						>
							6
						</span>
						<span className='spanToFade'>2</span>
						<span className='hiddenChar'>0</span>
					</div>

					<div
						id='twentyTwoHeading'
						ref={(el) => {
							twentyTwoHeadingRef = el;
						}}
					>
						<span className='hiddenChar'>1</span>
						<span className='spanToFade'>2</span>
						<span
							className='spanChar'
							ref={(el) => {
								spanCharTwoRef = el;
							}}
						>
							0
						</span>
						<span className='spanToFade'>22</span>
					</div>
				</main>

				{/* Diamond Grid */}
				{data && tempImages && (
					<LargeDiamondGrid
						pageSelected={pageSelected}
						previousPage={previousPage}
						navDirection={navDirection}
						pageData={data}
						tempImages={tempImages}
						homeEventHandler={homeEventHandler}
						updatePauseCarouselHandler={updatePauseCarouselHandler}
					/>
				)}
				{/* Pagination */}
				<div className='paginationBtnContainer'>
					<button
						className='leftNavButton'
						ref={(el) => {
							leftNavButtonRef = el;
						}}
						value='left'
						onClick={(e) => {
							AnalyticsEvent(
								"english",
								"/",
								`${pageSelected - 1}:arrow-previous`
							);
							handlePageChange("left");
						}}
						style={{
							visibility: `${is.mobile() ? "hidden" : "visible"}`,
						}}
						aria-label='Go to previous page'
					>
						<ArrowLeftIcon className='chevronArrow' />
					</button>

					<button
						className='rightNavButton'
						ref={(el) => {
							rightNavButtonRef = el;
						}}
						value='right'
						onClick={(e) => {
							AnalyticsEvent("english", "/", `${pageSelected + 1}:arrow-next`);
							handlePageChange("right");
						}}
						style={{
							visibility: `${is.mobile() ? "hidden" : "visible"}`,
						}}
						aria-label='Go to next page'
					>
						<ArrowRightIcon className='chevronArrow' />
					</button>
				</div>

				<div
					className='pageMarkerContainer'
					ref={(el) => {
						pageMarkerContainerRef = el;
					}}
				>
					{data.pages.map((page, index) => (
						<div
							onClick={
								!is.mobile()
									? () => {
											setNavDirection(
												pageSelected > index + 1 ? "left" : "right"
											);
											AnalyticsEvent(
												"english",
												"/",
												`${index + 1}:page-marker`
											);
											setPreviousPage(pageSelected);
											setPageSelected(index + 1);
									  }
									: undefined
							}
							className={`pageMarker ${
								pageSelected === index + 1 ? "activeMarker" : ""
							}`}
							style={{ width: `${100 / data.pages.length}%` }}
							key={index}
						></div>
					))}
				</div>
			</div>
		</Div100vh>
	) : (
		<div className='loader'></div>
	);
}

export default App;
